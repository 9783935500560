import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown"];

  toggle(event) {
    const theme = event.currentTarget.dataset && event.currentTarget.dataset.theme;

    if (theme) {
      this.dropdownTargets.forEach((dropdown) => {
        if (dropdown.dataset.theme === theme) {
          dropdown.classList.toggle("hidden");
        } else {
          dropdown.classList.add("hidden");
        }
      });
    }
  }

  close(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTargets.forEach((dropdown) => {
        dropdown.classList.add("hidden");
      });
    }
  }
}
